export const blogData = [
    {
        id: 1,
        title: "Wireless Networking Basics",
        urlPath:"Wireless-Networking-Basics",
        category: "Network",
        subCategory: ["Network", "Wireless"],
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat orci. Tristique sollicitudin nibh sit amet commodo nulla. Suscipit tellus mauris a diam maecenas sed enim. Accumsan in nisl nisi scelerisque. Quis risus sed vulputate odio ut enim blandit volutpat maecenas. In ornare quam viverra orci sagittis eu volutpat odio facilisis. Commodo elit at imperdiet dui accumsan sit amet nulla. Eget magna fermentum iaculis eu non diam phasellus vestibulum. Luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor. Senectus et netus et malesuada fames ac turpis egestas. Sed risus pretium quam vulputate dignissim suspendisse in est ante. Vitae ultricies leo integer malesuada nunc. Nisl rhoncus mattis rhoncus urna neque viverra justo nec. Massa enim nec dui nunc mattis enim ut tellus. Augue lacus viverra vitae congue eu consequat ac felis donec. Libero enim sed faucibus turpis in eu mi bibendum. Ultrices eros in cursus turpis massa.",
        coverPicture: "/image/Blog_Image_1.webp",
        createdDate: "1 May 2023",
    },
    {
        id: 2,
        title: "What Is a Proxy Server?",
        urlPath: "What-Is-a-Proxy-Server",
        category: "Network",
        subCategory: ["Network", "Computer Science"],
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat orci. Tristique sollicitudin nibh sit amet commodo nulla. Suscipit tellus mauris a diam maecenas sed enim. Accumsan in nisl nisi scelerisque. Quis risus sed vulputate odio ut enim blandit volutpat maecenas. In ornare quam viverra orci sagittis eu volutpat odio facilisis. Commodo elit at imperdiet dui accumsan sit amet nulla. Eget magna fermentum iaculis eu non diam phasellus vestibulum. Luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor. Senectus et netus et malesuada fames ac turpis egestas. Sed risus pretium quam vulputate dignissim suspendisse in est ante. Vitae ultricies leo integer malesuada nunc. Nisl rhoncus mattis rhoncus urna neque viverra justo nec. Massa enim nec dui nunc mattis enim ut tellus. Augue lacus viverra vitae congue eu consequat ac felis donec. Libero enim sed faucibus turpis in eu mi bibendum. Ultrices eros in cursus turpis massa.",
        coverPicture: "/image/Blog_Image_2.webp",
        createdDate: "2 May 2023",
    },
    {
        id: 3,
        title: "NAT As Security & IP Preservation Technique",
        urlPath: "NAT-As-Security-And-IP-Preservation-Technique",
        category: "Network",
        subCategory: ["Network", "Computer Science"],
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat orci. Tristique sollicitudin nibh sit amet commodo nulla. Suscipit tellus mauris a diam maecenas sed enim. Accumsan in nisl nisi scelerisque. Quis risus sed vulputate odio ut enim blandit volutpat maecenas. In ornare quam viverra orci sagittis eu volutpat odio facilisis. Commodo elit at imperdiet dui accumsan sit amet nulla. Eget magna fermentum iaculis eu non diam phasellus vestibulum. Luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor. Senectus et netus et malesuada fames ac turpis egestas. Sed risus pretium quam vulputate dignissim suspendisse in est ante. Vitae ultricies leo integer malesuada nunc. Nisl rhoncus mattis rhoncus urna neque viverra justo nec. Massa enim nec dui nunc mattis enim ut tellus. Augue lacus viverra vitae congue eu consequat ac felis donec. Libero enim sed faucibus turpis in eu mi bibendum. Ultrices eros in cursus turpis massa.",
        coverPicture: "/image/Blog_Image_3.webp",
        createdDate: "3 May 2023",
    },
    {
        id: 4,
        title: "DHCP Discovery Process",
        urlPath: "DHCP-Discovery-Process",
        category: "Network",
        subCategory: ["Network", "Computer Science"],
        content: "In order for a computer to work, we need to configure four things: 1) an IP address, 2) a subnet mask, 3) a primary gateway, and 4) a DNS server. Let’s say that you are an IT administrator in a company, and you have to configure 100 computers at once. You can probably have the same subnet mask, primary gateway, and a DNS server for the lot. However, IP addresses have to be different for each computer. It would be a nightmare for you to configure it one by one. You need to rely on a system which does the job automatically. This is where a DHCP server comes into the picture. Let’s learn about what it is and what the process of DHCP discovery (meaning the process of assigning an IP address to a node) looks like.",
        coverPicture: "/image/Blog_Image_4.webp",
        createdDate: "4 May 2023",
    },
    {
        id: 5,
        title: "The TCP/IP Five-Layer Network Model",
        urlPath:"The-TCPIP-Five-Layer-Network-Model",
        category: "Network",
        subCategory: ["Network", "Computer Science"],
        content: "There are some models that explain how devices communicate with each other on the Internet. One of the most popular ones is TCP/IP five-layer network model. When the information travels from one end to another, packets travel across five different layers, defined by different protocols used. Let’s look at each layer, starting from the bottom of the stack.",
        coverPicture: "/image/Blog_Image_5.webp",
        createdDate: "5 May 2023",
    },
    {
        id: 6,
        title: "The Physical Layer: Cables, Ports, and Technologies behind",
        urlPath: "The-Physical-Layer-Cables-Ports-and-Technologies-behind",
        category: "Network",
        subCategory: ["Network", "Computer Science"],
        content: "Cat 5, Cat 5e, and Cat 6 cables are the most common copper cables out there today. They are short for Category 5, and Category 6 cables respectively. They look very similar to our naked eyes, but their constructs differ, resulting in different usable lengths and transfer rates. Cat 5 is the oldest of all and has been replaced by Cat 5e and 6. Cat 5e and 6 are made in a way to reduce crosstalk (electric charge is erroneously detected by another wire, causing network error). Following stricter specifications, Cat 6 is the most reliable cable of all. However, because of its structure, its maximum distance is shorter than Cat 5e.",
        coverPicture: "/image/Blog_Image_6.webp",
        createdDate: "6 May 2023",
    },
    {
        id: 7,
        title: "Data Link Layer: What is a MAC address made up of?",
        urlPath:"Data-Link-Layer-What-is-a-MAC-address-made-up-of",
        category: "Network",
        subCategory: ["Network", "Computer Science"],
        content: "MAC addresses are 12 digits (48 bits or 6 bytes) numbers with separation colons between each two-digit. They utilise hexadecimal (sixteen) numbers with 0 to 9, followed by A to F. The format of a MAC address typically looks like the below: The first six digits are called OUI (Organizationally Unique Identifier) and are determined by the manufacturer of the device. The latter half of a MAC address is unique to the device. When you want to identify a device’s manufacturer, you can look it up online by searching for the OUI of a MAC address. But be careful not to type the latter half of the MAC address for privacy and security reasons.",
        coverPicture: "/image/Blog_Image_7.webp",
        createdDate: "7 May 2023",
    },
    {
        id: 8,
        title: "What is Unicast, Multicast, Broadcast, or Anycast?",
        urlPath: "What-is-Unicast-Multicast-Broadcast-or-Anycast",
        category: "Network",
        subCategory: ["Network", "Computer Science"],
        content: "Unicast is a term describing a one-to-one transmission. When we send packets from one device directly to another, it is unicast. For example, client-server architecture (a computer requesting a server for information), transmissions between two computers, or between servers fall into unicast.",
        coverPicture: "/image/Blog_Image_8.webp",
        createdDate: "8 May 2023",
    },
    {
        id: 9,
        title: "Data Link Layer: Dissecting Ethernet Frame",
        urlPath: "Data-Link-Layer-Dissecting-Ethernet-Frame",
        category: "Network",
        subCategory: ["Network", "Computer Science"],
        content: "At the top of the stack, we have the application layer. Here, we have a message that we want to send across the Internet to the destination computer. When the message is sent out to the transport layer, the message is put in an envelope with the information needed for the next layer to process data. This envelope then reaches the next layer at the network layer where another envelope is added on top. Until the physical layer, this process is repeated, encapsulating itself multiple times. ",
        coverPicture: "/image/Blog_Image_9.webp",
        createdDate: "9 May 2023",
    },
];