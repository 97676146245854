import React from 'react'
import './BlogTitle.css';

export const BlogTitle = () => {
  return (
    <div>
        <h1
        id='blog-title'>Jessie's Mock Blog</h1>
    </div>
  )
}
